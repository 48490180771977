import React, { useState, useEffect } from "react";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeLocalization from "./components/ThemeLocalization";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import usePageTracking from "./hooks/usePageTracking";

// New onboarding modal import
import UserOnboardingModal from "./components/modals/UserOnboardingModal";

if (process.env.NODE_ENV === "production") disableReactDevTools();

const App: React.FC = () => {
  usePageTracking(); 
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

  useEffect(() => {
    const hasSeenOnboardingModal = localStorage.getItem('hasSeenOnboardingModal');
    setIsFirstTimeUser(!hasSeenOnboardingModal);
  }, []);

  const handleCloseOnboardingModal = () => {
    setIsFirstTimeUser(false);
    localStorage.setItem('hasSeenOnboardingModal', 'true');
  };

  return (
    <ThemeProvider>
      <ThemeLocalization>
        <NotistackProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />

            <UserOnboardingModal 
              isFirstTimeUser={isFirstTimeUser}
              onClose={handleCloseOnboardingModal}
            />
          </MotionLazyContainer>
        </NotistackProvider>
      </ThemeLocalization>
    </ThemeProvider>
  );
};

export default App;