import { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ArtistProps } from "@types";
import Avatar from "components/Avatar";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateFailure, updateStart, updateSuccess } from "../../redux/redux-slices/UserSlice";

export default function FandomCard({
  avatarImg,
  username,
  _id,
  hideCancelButton = false,
  onClick,
}: ArtistProps & { 
  hideCancelButton?: boolean;
  onClick?: () => void;
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.currentUser);

  const handleCardClick = (e: React.MouseEvent) => {
    // Only navigate if we didn't click the cancel button or its container
    if (onClick && !(e.target as HTMLElement).closest('button')) {
      onClick();
    }
  };

  const handleCancelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (_: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    setOpen(false);
  };

  const handleDialogButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    dispatch(updateStart());
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/users/subscription/cancel/${_id}`);
      const updateSubscribers = user?.subscribers?.filter((id: string) => id != _id);
      let copyUser = { ...user };
      copyUser!.subscribers = updateSubscribers;
      dispatch(updateSuccess(copyUser));
    } catch (err) {
      console.log(err);
      dispatch(updateFailure());
    }
    setOpen(false);
  };

  return (
    <Box
      onClick={handleCardClick}
      sx={{
        borderRadius: 2,
        bgcolor: "common.black",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        textDecoration: "none",
        cursor: "pointer",
        width: "100%",
        aspectRatio: "4 / 3",
        "&:hover": {
          boxShadow: 4,
        },
      }}
    >
      <Stack
        spacing={1}
        sx={{
          paddingInline: { xs: 1.5, sm: 2 },
          paddingBlock: { xs: 1.5, sm: 2 },
          flexGrow: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "common.white",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
          }}
        >
          12/MONTHS
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: "common.white",
            textTransform: "uppercase",
            fontSize: { xs: "0.625rem", sm: "0.75rem" },
          }}
        >
          fan TIME
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{
          bgcolor: "common.white",
          padding: { xs: 0.5, sm: 0.5 },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            src={avatarImg}
            alt="user avatar"
            sx={{ width: { xs: 24, sm: 32 }, height: { xs: 24, sm: 32 } }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "0.625rem", sm: "0.75rem" },
                fontWeight: "bold",
              }}
            >
              {username}
            </Typography>
          </Box>
        </Stack>
        {!hideCancelButton && (
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: "common.black",
              color: "common.white",
              textTransform: "uppercase",
              borderRadius: 1,
              boxShadow: "none",
              fontSize: { xs: "0.5rem", sm: "0.625rem" },
              padding: { xs: "2px 4px", sm: "4px 8px" },
              "&:hover": {
                backgroundColor: "rgba(253, 147, 76, 0.8)",
              },
            }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        )}
      </Stack>
      <Dialog 
        open={open} 
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>Confirm Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogButtonClick}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              "&:hover": {
                color: "#fff",
                backgroundColor: "rgba(253, 147, 76, 0.8)",
              },
            }}
          >
            No
          </Button>
          <Button 
            onClick={handleConfirm}
            sx={{ color: "#000" }} 
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
