import React, { useState, useMemo } from "react";
import { Box, Stack, Typography, Select, MenuItem, FormControl, Collapse } from "@mui/material";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import { CommentType } from "@types";

interface CommentsSectionProps {
  comments: CommentType[];
  onAddComment: (text: string) => void;
  onVoteComment: (commentId: string, delta: number) => void;
  onReplyComment: (parentId: string, text: string) => void;
  onLoadMore: (parentId: string) => void;
  onEditComment: (commentId: string, newText: string) => void;
  onDeleteComment: (commentId: string) => void;
  isVisible?: boolean;
}

type SortOption = "newest" | "oldest" | "most_votes" | "most_replies";
type FilterOption = "all" | "top_100" | "no_replies" | "has_replies";

const CommentsSection: React.FC<CommentsSectionProps> = ({
  comments,
  onAddComment,
  onVoteComment,
  onReplyComment,
  onLoadMore,
  onEditComment,
  onDeleteComment,
  isVisible = false,
}) => {
  const [sortBy, setSortBy] = useState<SortOption>("newest");
  const [filterBy, setFilterBy] = useState<FilterOption>("all");

  const sortedAndFilteredComments = useMemo(() => {
    let processedComments = [...comments];

    // Sort comments
    // switch (sortBy) {
    //   case "oldest":
    //     processedComments.sort((a, b) => a.id - b.id);
    //     break;
    //   case "newest":
    //     processedComments.sort((a, b) => b.id - a.id);
    //     break;
    //   case "most_votes":
    //     processedComments.sort((a, b) => b.count - a.count);
    //     break;
    //   case "most_replies":
    //     processedComments.sort((a, b) => b.replies.length - a.replies.length);
    //     break;
    // }

    // Filter comments
    switch (filterBy) {
      case "top_100":
        processedComments = processedComments.slice(0, 100);
        break;
      case "no_replies":
        processedComments = processedComments.filter((comment) => comment.replies.length === 0);
        break;
      case "has_replies":
        processedComments = processedComments.filter((comment) => comment.replies.length > 0);
        break;
    }

    return processedComments;
  }, [comments, sortBy, filterBy]);

  return (
    <Collapse in={isVisible} timeout='auto'>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 1,
          overflow: "hidden",
          transition: "all 0.3s ease",
          px: 2,
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 1, sm: 2 }}
          sx={{
            ml: 2,
            py: 1.5,
            borderBottom: "1px solid",
            borderColor: "divider",
            bgcolor: "background.default",
            flexWrap: "nowrap",
            width: "100%",
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 500,
              color: "text.primary",
              whiteSpace: "nowrap",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            {comments.length} {comments.length === 1 ? "Comment" : "Comments"}
          </Typography>

          <Stack
            direction='row'
            spacing={1}
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <FormControl size='small' sx={{ minWidth: { xs: 110, sm: 130 } }}>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as SortOption)}
                sx={{
                  "& .MuiSelect-select": {
                    py: 0.75,
                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  },
                }}
              >
                <MenuItem value='newest'>Newest First</MenuItem>
                <MenuItem value='oldest'>Oldest First</MenuItem>
                <MenuItem value='most_votes'>Most Votes</MenuItem>
                <MenuItem value='most_replies'>Most Replies</MenuItem>
              </Select>
            </FormControl>

            <FormControl size='small' sx={{ minWidth: { xs: 100, sm: 120 } }}>
              <Select
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value as FilterOption)}
                sx={{
                  "& .MuiSelect-select": {
                    py: 0.75,
                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  },
                }}
              >
                <MenuItem value='all'>Show All</MenuItem>
                <MenuItem value='top_100'>Top 100</MenuItem>
                <MenuItem value='no_replies'>No Replies</MenuItem>
                <MenuItem value='has_replies'>Has Replies</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        {/* Comments List */}
        <Box sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            {sortedAndFilteredComments.map((comment) => (
              <Comment
                // timestamp={comment.createdAt} 
                key={comment._id}
                {...comment}
                // onVote={onVoteComment}
                onReply={onReplyComment}
                onLoadMore={onLoadMore}
                onEdit={onEditComment}
                onDelete={onDeleteComment}
                depth={0}
              />
            ))}

            {sortedAndFilteredComments.length === 0 && (
              <Box
                sx={{
                  py: 4,
                  textAlign: "center",
                  color: "text.secondary",
                  bgcolor: "background.default",
                  borderRadius: 1,
                }}
              >
                <Typography variant='body2'>
                  {filterBy === "all"
                    ? "No comments yet. Be the first to comment!"
                    : "No comments match the selected filter."}
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Collapse>
  );
};

export default CommentsSection;
