import React from "react";
import { Dialog, DialogTitle, DialogActions, Button, Typography, Box, Stack } from "@mui/material";
import Image from "components/Image";

interface UserOnboardingModalProps {
  isFirstTimeUser: boolean;
  onClose: () => void;
}

const UserOnboardingModal: React.FC<UserOnboardingModalProps> = ({ isFirstTimeUser, onClose }) => {
  return (
    <Dialog
      open={isFirstTimeUser}
      onClose={onClose}
      aria-labelledby='onboarding-dialog-title'
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "90vh",
          overflow: "auto",
        },
      }}
    >
      <DialogTitle id='onboarding-dialog-title' sx={{ textAlign: "center", paddingBottom: 5 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Image
            src='/image.png'
            alt='Onboarding'
            style={{
              width: "100%",
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </Box>
        <Typography variant='h4' component='span' sx={{ fontSize: "1.5rem" }}>
          Welcome To SOUNDCTRL
        </Typography>
        <Typography
          variant='body2'
          sx={{
            fontSize: "10px",
            textAlign: "center",
            maxWidth: "250px",
            margin: "0 auto",
          }}
        >
          We reward fans for being fans. <br /> Let's get you setup.
        </Typography>
      </DialogTitle>

      <Box sx={{ width: "100%", mb: 1 }}>
        <Image
          src='/onboard-text.png'
          alt='Onboarding'
          style={{
            marginLeft: "10px",
            width: "100%",
            borderRadius: "12px",
            objectFit: "cover",
          }}
        />
      </Box>

      <DialogActions sx={{ justifyContent: "center", padding: 1.5 }}>
        <Button
          onClick={onClose}
          variant='contained'
          size='medium'
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Get Started!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserOnboardingModal;
